@import "../../styles/mixing.scss";
@import "../../styles/variables.scss";

#home {
  display: flex;
  align-items: center;
  height: 100vh;

  .home_texts {
    @include fl-col-ctr;

    .title_sub {
      color: $text-dark;
      font-size: clamp(1.5rem, 1.2rem + 2vw, 2.5rem);
    }

    .title_main {
      font-family: $logo-font;
      color: $logoColor;
      font-size: clamp(2rem, 2rem + 2.25vw, 4rem);
    }
  }

  .button_list {
    @include fl-col-ctr;
    margin-top: 3em;

    .btn {
      text-transform: uppercase;
      font-weight: 800;
      border-radius: 6px;
    }

    .solid {
      background: $brandColorDark;

      padding: 12px 40px;
      color: $site-white;
      transition: 0.4s ease-in-out;
    }

    .solid:hover {
      background: $brandColorLight;
      color: $text-dark;
    }

    .line {
      margin-top: 1em;
      border: 3px solid $text-dark;
      color: $text-dark;
      padding: 11px 30px;
    }

    .line:hover {
      border: 3px solid $logoColor;
      color: $logoColor;
      transition: 0.4s ease-in-out;
    }
  }
}

.myPic {
  display: none;
  width: 100%;
}

@media only screen and (min-width: 700px) {
  #home {
    .button_list {
      flex-direction: row;

      .line {
        margin-top: 0;
        margin-left: 2em;
      }
    }
  }
}
