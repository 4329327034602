@import "./styles/body.scss";
@import "./styles/variables.scss";
@import "./styles/mixing.scss";

.wrapper {
  @include fl-col-ctr;
  max-width: 1250px;
  width: 95%;
  margin: 0 auto;
}

.link {
  text-decoration: none;
}

.section_h1 {
  font-family: $logo-font;
  color: $logoColor;
  font-size: clamp(2rem, 1.4rem + 1.5vw, 4.5rem);
}

.section_h4 {
  color: $brandColor;
  font-size: clamp(0.75rem, 0.8rem + 0.5vw, 2rem);
}

.headNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  z-index: 88;
  background: none;
  transition: 1s;

  .wrapper {
    @include fl-row-ctr;
    justify-content: space-between;
  }

  .navLinks {
    box-shadow: 0px 0px 10px #b8b8b8;
    background: #fffcecf2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: -1000px;
    width: 100%;
    max-width: 450px;
    height: 100vh;
    padding: 120px 50px;
    font-size: 2rem;
    transition: right 0.7s ease-in-out;

    .link {
      display: inline-block;
      padding: 10px;
      color: $text-dark;
      font-size: 2rem;
      font-weight: 800;
      text-transform: uppercase;

      transition: 0.5s;
    }
    .link:hover {
      color: $logoColor;
    }
  }

  .showNav {
    right: 0;
  }
}

.fadeIn {
  background: #689668;
}

.myNameLogo {
  font-family: $logo-font;
  color: $logoColor;
  font-size: clamp(1.75rem, 1.3rem + 2.25vw, 2.5rem);
  transition: 1s;
}

.myNameLogo:hover {
  color: $text-dark;
}

#hamburger {
  position: relative;
  color: $text-dark;
  font-size: clamp(1.8rem, 1.5rem + 2.25vw, 2.5rem);
  z-index: 800;

  .navToggle {
    cursor: pointer;
    transition: 0.3s;
  }

  .navToggle:hover {
    color: $logoColor;
  }
}

h1 {
  color: $text-dark;
  font-size: 2.5em;
  font-weight: 800;
}

h4 {
  color: $text-grey;
  font-weight: 500;
  font-size: 1.5em;
}

.footer {
  @include fl-col-ctr;
  height: 15vh;
  background: $brandColorLight;
  width: 100vw;

  .footer-h4 {
    color: $logoColor;
    font-family: $logo-font;
    margin-top: 2em;
  }

  ul {
    @include fl-row-ctr;
    height: 30%;
    width: 50%;
    margin: auto;
    justify-content: space-evenly;

    .link {
      font-size: 2rem;
      text-decoration: none;
      color: $text-dark;
      transition: 0.5s;
    }

    .link:hover {
      color: $text-grey;
    }
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (min-width: 840px) {
  .wrapper {
    width: 90%;
  }
  #hamburger {
    display: none;
  }

  #navLinks {
    all: unset;
    @include fl-row-ctr;
  }
}
