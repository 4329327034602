// Site Colours

$site-white: #fffcec;
$site-black: #12600b;
$brandColor: #9ac696;
$brandColorLight: #b6ffaf;
$brandColorDark: #88c683;
$logoColor: #8b4511;

/// Text Colors
$text-dark: $site-black;
$text-light: $site-white;
$text-grey: #8b451187;

// Fonts
$body-font: "Open Sans", sans-serif;
$logo-font: "Fugaz One", sans-serif;
