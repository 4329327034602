@import "../../styles/mixing.scss";
@import "../../styles/variables.scss";

#skills {
  margin-top: 2em;
  padding: 150px 0;
  background: linear-gradient(
    97.5deg,
    rgba(182, 255, 175, 0.49) -2.98%,
    rgba(70, 134, 64, 0.4802) 87.65%
  );
  transform: rotate(0.06deg);

  .skills_listContainer {
    margin-top: 2.5em;
    padding: 0;
    ul {
      width: 100%;
      gap: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;

      .skill_icon {
        @include fl-col-ctr;
        width: 120px;
        color: $text-dark;
        font-weight: 800;
        text-transform: uppercase;
        i {
          @include fl-col-ctr;
          // width: 40px;
          // height: 40px;
          margin-bottom: 8px;
          font-size: 3.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  #skills {
    margin: 7em 0;

    .skills_listContainer {
      ul {
        gap: 50px;
      }
    }
  }
}
