@import "../../styles/mixing.scss";
@import "../../styles/variables.scss";

#projects {
  margin-top: 2em;

  .project_card {
    @include fl-col-ctr;
    margin: 2em auto;

    .project_image_container {
      @include fl-col-ctr;
      margin: 1em auto;
      width: 100%;

      img {
        border-radius: 3px;
        width: 100%;
        max-width: 600px;
        object-fit: contain;
      }
    }

    .project_describe {
      width: 100%;
      color: $text-dark;
      font-size: 1.1rem;

      p {
        font-weight: 500;
      }
    }

    .project-title {
      color: $logoColor;
      font-family: $logo-font;
      margin-bottom: 0.5em;
    }

    .project_describe-title {
      color: $text-dark;
      font-family: $logo-font;
    }

    .project_link {
      text-decoration: none;
    }

    .btn {
      width: 100%;
      max-width: 400px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      border-radius: 6px;
    }

    .solid {
      background: $brandColorDark;
      margin-bottom: 1em;
      padding: 12px 40px;
      color: $site-white;
      transition: 0.4s ease-in-out;
    }

    .solid:hover {
      background: $brandColorLight;
      color: $text-dark;
    }
  }
}

@media screen and (min-width: 800px) {
  #projects {
    .project_card {
      @include fl-row-ctr;
      gap: 2em;
    }
  }
}
